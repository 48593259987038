// import React, { useState } from 'react';
// import axios from 'axios';
// // import "./Login.css"


// function Login() {
//   const [username, setUsername] = useState('');
//   const [password, setPassword] = useState('');
//   const [error, setError] = useState(false);
//   const [message, setMessage] = useState('');
//   let isLoggedin = false;

//   const handleSubmit = async (e) => {
//     e.preventDefault();
    
//     try {
//         const response = await axios.post('http://localhost:8000/login/', {
//             username,
//             password,
//         });
//             setMessage(response.data.message);
//         sessionStorage.setItem("username", response.data.username)
//         isLoggedin = true;
//         window.location.href = '/';
//       } catch (error) {
//         console.error('Error logging user:', error.message);
//         setError('Error loggin user. Please try again.');
//       }

//     // Here you can add logic to authenticate user with a server or API
//     // For simplicity, let's assume authentication is successful
    
//   };

//   return (
//     <div>
//             <h2>Login</h2>
//             <form onSubmit={handleSubmit}>
//                 <input
//                     type="text"
//                     placeholder="Username"
//                     value={username}
//                     onChange={(e) => setUsername(e.target.value)}
//                     required
//                 />
//                 {/* <input
//                     type="text"
//                     placeholder="EmailID"
//                     value={email}
//                     onChange={(e) => setEmail(e.target.value)}
//                     required
//                 /> */}
//                 <input
//                     type="password"
//                     placeholder="Password"
//                     value={password}
//                     onChange={(e) => setPassword(e.target.value)}
//                     required
//                 />
//                 <button type="submit">Register</button>
//             </form>
//             <p>{message}</p>
//             <a href="/register">
//                 Not a member? Sign up here!  </a>
//             {/* <strong>New User? Register here!</strong> */}
//         </div>
//   );
// }

// export default Login;


import React, { useState } from 'react';
import axios from 'axios';
import './loginPage.css';

function Login() {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState(false);
    const [message, setMessage] = useState('');
    let isLoggedin = false;

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await axios.post('http://localhost:8000/login/', {
                username,
                password,
            });
            setMessage(response.data.message);
            sessionStorage.setItem('username', response.data.username);
            isLoggedin = true;
            window.location.href = '/';
        } catch (error) {
            console.error('Error logging user:', error.message);
            setError('Error logging user. Please try again.');
        }
    };

    return (
        <div>
            

            {/* Login Form */}
            <div className="container">
                <h2>Login</h2>
                <form onSubmit={handleSubmit}>
                    <input
                        type="text"
                        placeholder="Username"
                        value={username}
                        onChange={(e) => setUsername(e.target.value)}
                        required
                    />
                    <input
                        type="password"
                        placeholder="Password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        required
                    />
                    <button type="submit">Login</button>
                </form>
                {error && <p style={{ color: 'red' }}>{error}</p>}
                <p>{message}</p>
                <a href="/register">Not a member? Sign up here!</a>
            </div>
        </div>
    );
}

export default Login;
